<template>
  <div class="mx-8">
    <v-subheader class="py-0 d-flex justify-space-between">
      <h3>
        USER ADMIN
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              x-small
              class="primary ml-2"
              v-bind="attrs"
              v-on="on"
              @click="dialogSearch = true"
              ><v-icon>mdi-magnify</v-icon></v-btn
            >
          </template>
          <span>Cari Data</span>
        </v-tooltip>
      </h3>

      <v-dialog v-model="dialogAdd" width="700">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="success" v-bind="attrs" v-on="on"> Tambah Data </v-btn>
        </template>

        <v-card>
          <v-card-title class="text-h5 success white--text">
            Tambah Data
          </v-card-title>

          <br />

          <v-card-text>
            <v-form ref="form" v-model="valid">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    label="username"
                    v-model="dataAdd.username"
                    :rules="requiredRules"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Nama"
                    v-model="dataAdd.nama"
                    :rules="requiredRules"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-select
                    label="Wilayah"
                    v-model="dataAdd.wilayah"
                    :items="wilayahOptions"
                    @change="getListSatker(dataAdd.wilayah)"
                    :rules="requiredRules"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    label="Satuan Kerja"
                    v-model="dataAdd.satker"
                    :items="satkerOptions"
                    :rules="requiredRules"
                    required
                  ></v-select>
                </v-col>
              </v-row>

              <div class="mt-2"></div>

              <span><h3>Group</h3></span>
              <v-row>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                  v-for="(group, index) in listDataGroup"
                  :key="index"
                >
                  <v-checkbox
                    v-model="dataAdd.group"
                    :label="group.subgroup"
                    :value="group._id"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialogAdd = false">
              Batal
            </v-btn>
            <v-btn color="primary" text @click="save()"> Simpan </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogSearch" width="700">
        <v-card>
          <v-card-title class="text-h5 success white--text">
            Pencarian Data
          </v-card-title>

          <br />

          <v-card-text>
            <v-form>
              <v-row>
                <v-col cols="12" md="4">
                  <v-select
                    label="Jenis"
                    v-model="dataSearch.tipe.jenis"
                    :items="jenisOptions"
                    @change="getWilayahSatker(dataSearch.tipe.jenis)"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="8">
                  <v-select
                    label="Wilayah / Satuan Kerja"
                    v-model="dataSearch.tipe.id"
                    :items="tipeOptions"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    label="username"
                    v-model="dataSearch.username"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Nama"
                    v-model="dataSearch.nama"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-select
                    label="Aktif"
                    v-model="dataSearch.status"
                    :items="statusOptions"
                  ></v-select>
                </v-col>
              </v-row>

              <div class="mt-2"></div>

              <span><h3>Group</h3></span>
              <v-row>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                  v-for="(group, index) in listDataGroup"
                  :key="index"
                >
                  <v-checkbox
                    v-model="dataSearch.usergroup"
                    :label="group.subgroup"
                    :value="group._id"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="3">
                  <v-select
                    label="Urutkan"
                    v-model="dataSearch.sort"
                    :items="sortOptions"
                  ></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="onClearSearch()"> Reset </v-btn>
            <v-btn color="primary" text @click="onSearch()"> Cari </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-subheader>

    <div class="mb-2"></div>

    <v-data-table
      :headers="headers"
      :items="listUserAdmin.data"
      disable-sort
      hide-default-footer
    >
      <template v-slot:item.satker="{ item }">
        <span>{{ item.satker.label }}</span>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn class="primary" small @click="toDetailPage(item)">Lihat</v-btn>
      </template>

      <template v-slot:no-data>
        <label>Tidak ada data</label>
      </template>

      <template v-slot:footer>
        <v-divider class="mt-6"></v-divider>
        <v-row class="mt-3 mb-3" align="center" justify="end">
          <span class="caption">Limit:</span>
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn dark text color="primary" class="ml-2" v-on="on">
                {{ limit }}
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(number, index) in limitArray"
                :key="index"
                @click="updateLimit(number)"
              >
                <v-list-item-title>{{ number }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <span class="caption ml-4 mr-4"
            >Total Data: {{ listUserAdmin.jumlahdata }}</span
          >
          <span class="caption ml-4 mr-4"
            >Page {{ page }} of {{ listUserAdmin.jumlahhalaman }}</span
          >
          <v-btn small color="primary" class="mr-4" @click="prevPage">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn small color="primary" class="mr-4" @click="nextPage">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-row>
      </template>
    </v-data-table>

    <v-snackbar v-model="snackbar" color="success" timeout="1000">
      {{ snackbarText }}

      <template v-slot:action>
        <v-btn fab x-small color="white" @click="snackbar = false">
          <v-icon color="error">mdi-close-thick</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        { text: "No.", value: "nomor", width: "70px" },
        { text: "Username", value: "username" },
        { text: "Nama", value: "nama" },
        { text: "Satuan Kerja", value: "satker" },
        { text: "Detail", value: "actions" },
      ],
      page: 1,
      limit: 12,
      limitArray: [12, 24, 48],
      params: "",
      dialogAdd: false,
      dialogSearch: false,
      dataAdd: {
        username: "",
        nama: "",
        wilayah: "",
        satker: "",
        group: [],
      },
      dataSearch: {
        username: "",
        nama: "",
        status: "",
        usergroup: [],
        sort: "",
        tipe: {
          jenis: "",
          id: "",
        },
      },
      isAdvanceSearch: false,
      wilayahOptions: [],
      satkerOptions: [],
      groupOptions: [],
      jenisOptions: [
        { text: "WILAYAH", value: "KELOMPOK" },
        { text: "SATUAN KERJA", value: "SATKER" },
      ],
      tipeOptions: [],
      statusOptions: [
        { text: "YA", value: true },
        { text: "TIDAK", value: false },
      ],
      sortOptions: [
        { text: "A - Z", value: "ASC" },
        { text: "Z - A", value: "DESC" },
      ],
      valid: true,
      requiredRules: [(v) => !!v || "Data harus diisi"],
      snackbar: false,
      snackbarText: "",
    };
  },
  watch: {
    dialogAdd() {
      if (!this.dialogAdd) {
        this.$refs.form.reset();
        this.username = "";
        this.nama = "";
        this.wilayah = "";
        this.satker = "";
        this.group = [];
      }
    },
  },
  computed: {
    listUserAdmin() {
      return this.$store.getters.listUserAdmin;
    },
    listDataGroup() {
      return this.$store.getters.listDataGroup;
    },
  },
  created() {
    this.init();
    this.$store.dispatch("getListDataWilayah").then((res) => {
      res.data.forEach((element) => {
        if (element.isEnable == true) {
          this.wilayahOptions.push({ text: element.label, value: element._id });
        }
      });
    });
    this.$store.dispatch("getListDataGroup");
  },
  methods: {
    init() {
      if (this.isAdvanceSearch == true) {
        this.params = {
          page: this.page,
          limit: this.limit,
          username: this.dataSearch.username,
          nama: this.dataSearch.nama,
          status: this.dataSearch.status,
          usergroup: this.dataSearch.usergroup,
          sort: this.dataSearch.sort,
          tipe: JSON.stringify({
            jenis: this.dataSearch.tipe.jenis,
            id: this.dataSearch.tipe.id,
          }),
        };
      } else {
        this.params = {
          page: this.page,
          limit: this.limit,
        };
      }

      this.$store.dispatch("getListUserAdmin", this.params);
    },

    nextPage() {
      if (this.page + 1 <= this.listUserAdmin.jumlahhalaman) {
        this.page += 1;
        this.init();
      }
    },

    prevPage() {
      if (this.page - 1 >= 1) {
        this.page -= 1;
        this.init();
      }
    },

    updateLimit(number) {
      this.limit = number;
      this.init();
    },

    getListSatker(wilayah) {
      this.satkerOptions = [];

      const data = {
        kelompok: wilayah,
      };
      this.$store.dispatch("getListDataSatker", data).then((res) => {
        res.data.forEach((element) => {
          if (element.isEnable == true) {
            this.satkerOptions.push({
              text: element.label,
              value: element._id,
            });
          }
        });
      });
    },

    save() {
      this.$refs.form.validate();
      if (
        this.dataAdd.username != "" &&
        this.dataAdd.nama != "" &&
        this.dataAdd.wilayah != "" &&
        this.dataAdd.satker != "" &&
        this.dataAdd.group.length > 0
      ) {
        if (this.valid) {
          const data = {
            username: this.dataAdd.username,
            nama: this.dataAdd.nama,
            satker: this.dataAdd.satker,
            group: this.dataAdd.group,
          };
          this.$store.dispatch("addUserAdmin", data).then((value) => {
            this.snackbar = true;
            this.snackbarText = value.pesan;
            if (value.hasil == "success") {
              this.init();
              this.dialogAdd = false;
            }
          });
        }
      } else {
        this.snackbar = true;
        this.snackbarText =
          "Silahkan isi terlebih dahulu field username, nama, wilayah, satuan kerja, dan group";
      }
    },

    toDetailPage(item) {
      this.$router.push({
        name: "d_useradmin",
        params: { id: item._id },
      });
    },

    getWilayahSatker(jenis) {
      this.tipeOptions = [];
      if (jenis == "KELOMPOK") {
        this.$store.dispatch("getListDataWilayah").then((res) => {
          res.data.forEach((element) => {
            this.tipeOptions.push({ text: element.label, value: element._id });
          });
        });
      } else if (jenis == "SATKER") {
        this.$store.dispatch("getListDataSatker").then((res) => {
          res.data.forEach((element) => {
            this.tipeOptions.push({ text: element.label, value: element._id });
          });
        });
      }
    },

    onSearch() {
      this.isAdvanceSearch = true;
      this.page = 1;
      this.init();
      this.dialogSearch = false;
    },

    onClearSearch() {
      this.page = 1;
      this.dataSearch.username = "";
      this.dataSearch.nama = "";
      this.dataSearch.status = "";
      this.dataSearch.usergroup = [];
      this.dataSearch.sort = "";
      this.dataSearch.tipe.jenis = "";
      this.dataSearch.tipe.id = "";
      this.isAdvanceSearch = false;
      this.init();
      this.dialogSearch = false;
    },
  },
};
</script>